<template>
  <div class="">
    <br />
    <div class="row">
      <div class="col-4 row">
        <div class="col">Chambre :</div>
        <div class="col">
          {{ coldroom.fullName }}
        </div>
      </div>

      <div class="col-4 row">
        <div class="col">Nº :</div>
        <div class="col">
          {{ coldroom.number }}
        </div>
      </div>

      <div class="col-4 row">
        <div class="col">Type :</div>
        <div class="col">
          {{ coldroom.type }}
        </div>
      </div>
    </div>
    <br />
    <div class="row justify-content-end align-items-end">
      <div class="col-xl-3 col-lg-3 col-md-3 col-8">
        <label for=""> Date </label>
        <input type="date" v-model="temperature.date" class="form-control" />
      </div>

      <div class="col-xl-2 col-lg-2 col-md-2 col-4">
        <label for=""> Heure </label>
        <input type="time" v-model="temperature.time" class="form-control" />
      </div>
      <div class="col-xl-2 col-lg-2 col-md-2 col">
        <label for=""> Température </label>
        <input
          type="text"
          v-model="temperature.temperature"
          class="form-control"
        />
      </div>
      <div class="col-xl-auto col-lg-auto col-md-auto col-auto">
        <label for=""> </label>
        <button @click="save(temperature)" class="btn btn-success">
          Enregistrer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Date</th>
          <th scope="col">Heure</th>
          <th scope="col">Température</th>
          <th scope="col">Créer par</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in coldroom.temperatures" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ item.reference }}
          </td>
          <td>
            {{ item.dateTime | date }}
          </td>

          <td>
            {{ item.dateTime | hour }}
          </td>

          <td
            :class="
              item.temperature > 0 && coldroom.type == 'Négative'
                ? 'bg-warning'
                : item.temperature < 0 && coldroom.type == 'Positive'
                ? ' bg-warning'
                : ''
            "
          >
            <span
              :class="
                item.temperature > 0
                  ? 'text-success'
                  : item.temperature == 0
                  ? 'text-primary'
                  : item.temperature < 0
                  ? 'text-danger'
                  : ''
              "
              class=" "
            >
              {{ item.temperature }}
            </span>
            ºC
          </td>
          <td>
            {{ item.user_reference }}
          </td>
          <td class="text-danger">
            <button class="btn">
              <i class="bi bi-thermometer-half fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      temperature: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        time: new Date().toLocaleTimeString(),
      },
    };
  },
  computed: {
    ...mapGetters("coldroom", {
      coldroom: "getColdroom",
      temperatures: "getTemperatures",
    }),
  },
  mounted() {
    this.$store.dispatch("coldroom/show", this.$route.params.reference);
  },
  methods: {
    async search(value) {
      return await this.$store.commit("coldroom/search", value);
    },

    async save(data) {
      let temperature = data.temperature;
      let date = data.date;
      let time = data.time;
      let coldRoom_reference = this.coldroom.reference;
      await this.$store.dispatch("coldroom/storeTemperature", {
        temperature,
        coldRoom_reference,
        date,
        time,
      });
      this.temperature.temperature = "";
    },
    async refresh() {
      return await this.$store.dispatch("coldroom/getAll");
    },

    async listColdrooms(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("coldroom/getTodays");
      } else {
        return await this.$store.dispatch("coldroom/getAll");
      }
    },
    async sort(value) {
      return await this.$store.commit("coldroom/sort", value);
    },
  },
  filters: {
    date: function (date) {
      return moment(date).format("DD-MM-yyyy");
    },
    hour: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
